<template>
  <resource-search-behaviour />
</template>

<script>
import ResourceSearchBehaviour from './components/resource-search-behaviour'

export default {
  components: {
    ResourceSearchBehaviour
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.searches.title')
    }
  }
}
</script>
