<template>
  <th-wrapper
    :title="$t('pages.settings.searches.resources.title')"
    :subtitle="$t('pages.settings.searches.resources.explainer')"
  >
    <hr />

    <h4
      class="font-bold text-sm mt-8 mb-6"
      v-text="$t(`pages.settings.searches.resources.product.label`)"
    />

    <!-- Maximum Search Result Count -->
    <div class="mb-6">
      <el-switch
        v-model="productsOnlySelfSustained"
        :active-text="
          $t(
            'pages.settings.searches.resources.product.only_self_sustained.label'
          )
        "
      >
        <el-input v-model="productsOnlySelfSustained" />
      </el-switch>
    </div>

    <!-- Maximum Search Result Count -->
    <div>
      <th-input-title
        :title="$t(`pages.settings.searches.resources.product.limit.label`)"
      />
      <th-number-input
        v-model="productsLimit"
        :upper-limit="100"
        :locale="$i18n.locale"
        class="max-w-xs"
      />
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  data() {
    return {}
  },
  computed: {
    productsLimit: {
      get() {
        const storeVal = safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'settings.search.products.limit'
        )
        return storeVal || undefined
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.search.products.limit',
          value: Number.isFinite(newValue) ? newValue : undefined
        })
      }
    },
    productsOnlySelfSustained: {
      get() {
        const storeVal = safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'settings.search.products.only_self_sustained'
        )
        return storeVal || false
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.search.products.only_self_sustained',
          value: newValue
        })
      }
    }
  },
  methods: {}
}
</script>
